import type { ReactElement } from 'react';
import { Typography } from '@mui/material';
import type { PageProps } from 'gatsby';
import { graphql } from 'gatsby';
import Markdown from 'markdown-to-jsx';
import type { PatriotActPageQuery } from '../../../generated/types';
import { HomepageUsTemplate } from '../../components/templates/HomepageUsTemplate';
import { LegalContainer } from '../../modules/legal/LegalContainer';

type PpPatriotActDisclosure = PageProps<PatriotActPageQuery>;

function PatriotActDisclosure({ data: { content } }: PpPatriotActDisclosure): ReactElement {
  return (
    <HomepageUsTemplate seo={content?.frontmatter?.seo}>
      <LegalContainer sx={{ paddingBottom: 4, paddingTop: 10 }}>
        <Typography component="h1" variant="subHeadingL">
          {content?.frontmatter?.title}
        </Typography>
        {content?.rawMarkdownBody && <Markdown>{content?.rawMarkdownBody}</Markdown>}
      </LegalContainer>
    </HomepageUsTemplate>
  );
}

export const query = graphql`
  query PatriotActPage {
    content: markdownRemark(frontmatter: { layout: { eq: "patriotAct" } }) {
      rawMarkdownBody
      frontmatter {
        seo {
          title
          description
          image
        }
        title
      }
    }
  }
`;

export default PatriotActDisclosure;
