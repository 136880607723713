import type { ReactElement } from 'react';
import { useTheme } from '@mui/material/styles';
import type { PpWC } from '@noah-labs/fe-shared-ui-shared';
import { graphql, useStaticQuery } from 'gatsby';
import type {
  HomepageUsTemplateQuery,
  MarkdownRemarkFrontmatterHeaderMenuHeaderLinks,
} from '../../../generated/types';
import { BaseWrapper } from '../layout/BaseWrapper';
import { Footer } from '../layout/Footer';
import type { PpSeo } from '../layout/Seo';
import { Header } from '../navbar/Header';

type PpHomepageUsTemplate = {
  seo?: PpSeo | null;
} & PpWC;

const query = graphql`
  query HomepageUsTemplate {
    socials: markdownRemark(frontmatter: { layout: { eq: "info" }, locale: { eq: "en" } }) {
      frontmatter {
        socialMenu {
          socialLinks {
            socialName
            socialURL
          }
        }
      }
    }

    homepageUs: markdownRemark(frontmatter: { layout: { eq: "UsHomepage" } }) {
      frontmatter {
        legalPrefix
        legalCompanyName
        headerMenu {
          headerLinks {
            headerURL
            headerLink
            headerLabel
            sublinks {
              sublinkUrl
              sublinkLabel
            }
          }
          headerButtons {
            headerButton2
          }
          hideLanguageSwitch
        }
      }
    }
  }
`;

export function HomepageUsTemplate({ children, seo }: PpHomepageUsTemplate): ReactElement {
  const { homepageUs, socials } = useStaticQuery<HomepageUsTemplateQuery>(query);
  const theme = useTheme();

  return (
    <BaseWrapper
      FooterSlot={
        <Footer
          footerMenu={
            homepageUs?.frontmatter?.headerMenu
              ?.headerLinks as MarkdownRemarkFrontmatterHeaderMenuHeaderLinks[]
          }
          legalCompanyName={homepageUs?.frontmatter?.legalCompanyName}
          legalPrefix={homepageUs?.frontmatter?.legalPrefix}
          socialMenu={socials?.frontmatter?.socialMenu}
        />
      }
      HeaderSlot={
        <Header
          backgroundColor={theme.palette.websiteBrand.greyscale.semiTransparentWhite}
          color={theme.palette.websiteBrand.greyscale.darkGrey}
          headerMenu={homepageUs?.frontmatter?.headerMenu}
        />
      }
      seoProps={seo}
    >
      {children}
    </BaseWrapper>
  );
}
