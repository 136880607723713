import { Container, Stack } from '@mui/material';
import type { StackProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import type { PpWC } from '@noah-labs/fe-shared-ui-shared';

const StyledContainer = styled(Container)(({ theme }) => ({
  alignItems: 'center',
  fontWeight: theme.typography.fontWeightRegular,
  table: {
    border: `1px solid ${theme.palette.websiteBrand.greyscale.black}`,
  },
  td: {
    backgroundColor: theme.palette.websiteBrand.greyscale.lighterGrey,
  },
  textAlign: 'left',
  th: {
    backgroundColor: theme.palette.websiteBrand.greyscale.lightGrey,
  },
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(0, 3),
  },
}));

export function LegalContainer({ children, ...rest }: PpWC & StackProps): React.ReactElement {
  return (
    <Stack alignItems="center" width="100%" {...rest}>
      <StyledContainer disableGutters maxWidth="md">
        {children}
      </StyledContainer>
    </Stack>
  );
}
